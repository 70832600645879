<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户id，外键{zb_user.id}" prop="userId">
        <a-input v-model="form.userId" placeholder="请输入用户id，外键{zb_user.id}"/>
      </a-form-model-item>
      <a-form-model-item label="性别,0-保密 1-男 2-女" prop="sex">
      </a-form-model-item>
      <a-form-model-item label="标签(如：公司 ,家,学校 等等)" prop="addressLabel">
        <a-input v-model="form.addressLabel" placeholder="请输入标签(如：公司 ,家,学校 等等)"/>
      </a-form-model-item>
      <a-form-model-item label="收货人姓名" prop="reciverName">
        <a-input v-model="form.reciverName" placeholder="请输入收货人姓名"/>
      </a-form-model-item>
      <a-form-model-item label="收货人电话" prop="reciverTelephone">
        <a-input v-model="form.reciverTelephone" placeholder="请输入收货人电话"/>
      </a-form-model-item>
      <a-form-model-item label="是否默认" prop="isDefault">
        <a-input v-model="form.isDefault" placeholder="请输入是否默认"/>
      </a-form-model-item>
      <a-form-model-item label="经度" prop="lng">
        <a-input v-model="form.lng" placeholder="请输入经度"/>
      </a-form-model-item>
      <a-form-model-item label="纬度" prop="lat">
        <a-input v-model="form.lat" placeholder="请输入纬度"/>
      </a-form-model-item>
      <a-form-model-item label="省市区地区信息id(省份)外键{extra_area.area_id}" prop="areapId">
        <a-input v-model="form.areapId" placeholder="请输入省市区地区信息id(省份)外键{extra_area.area_id}"/>
      </a-form-model-item>
      <a-form-model-item label="省份" prop="areap">
        <a-input v-model="form.areap" placeholder="请输入省份"/>
      </a-form-model-item>
      <a-form-model-item label="省市区地区信息id(城市)外键{extra_area.area_id}" prop="areacId">
        <a-input v-model="form.areacId" placeholder="请输入省市区地区信息id(城市)外键{extra_area.area_id}"/>
      </a-form-model-item>
      <a-form-model-item label="城市" prop="areac">
        <a-input v-model="form.areac" placeholder="请输入城市"/>
      </a-form-model-item>
      <a-form-model-item label="省市区地区信息id(地区)外键{extra_area.area_id}" prop="areaxId">
        <a-input v-model="form.areaxId" placeholder="请输入省市区地区信息id(地区)外键{extra_area.area_id}"/>
      </a-form-model-item>
      <a-form-model-item label="县" prop="areax">
        <a-input v-model="form.areax" placeholder="请输入县"/>
      </a-form-model-item>
      <a-form-model-item label="详细地址" prop="detailedAddress">
        <a-input v-model="form.detailedAddress" placeholder="请输入详细地址"/>
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark">
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear/>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getAddress, addAddress, updateAddress} from '@/api/user/address'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        sex: null,

        addressLabel: null,

        reciverName: null,

        reciverTelephone: null,

        isDefault: null,

        lng: null,

        lat: null,

        areapId: null,

        areap: null,

        areacId: null,

        areac: null,

        areaxId: null,

        areax: null,

        detailedAddress: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          {required: true, message: '用户id，外键{zb_user.id}不能为空', trigger: 'blur'}
        ],
        reciverName: [
          {required: true, message: '收货人姓名不能为空', trigger: 'blur'}
        ],
        reciverTelephone: [
          {required: true, message: '收货人电话不能为空', trigger: 'blur'}
        ],
        areapId: [
          {required: true, message: '省市区地区信息id(省份)外键{extra_area.area_id}不能为空', trigger: 'blur'}
        ],
        areacId: [
          {required: true, message: '省市区地区信息id(城市)外键{extra_area.area_id}不能为空', trigger: 'blur'}
        ],
        areaxId: [
          {required: true, message: '省市区地区信息id(地区)外键{extra_area.area_id}不能为空', trigger: 'blur'}
        ]
      }
    }
  },
  filters: {},
  created() {
  },
  computed: {},
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        sex: null,
        addressLabel: null,
        reciverName: null,
        reciverTelephone: null,
        isDefault: null,
        lng: null,
        lat: null,
        areapId: null,
        areap: null,
        areacId: null,
        areac: null,
        areaxId: null,
        areax: null,
        detailedAddress: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getAddress({"id": id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateAddress(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addAddress(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
