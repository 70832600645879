import request from '@/utils/request'


// 查询地址列表
export function listAddress(query) {
  return request({
    url: '/user/user-address/list',
    method: 'get',
    params: query
  })
}

// 查询地址分页
export function pageAddress(query) {
  return request({
    url: '/user/user-address/page',
    method: 'get',
    params: query
  })
}

// 查询地址详细
export function getAddress(data) {
  return request({
    url: '/user/user-address/detail',
    method: 'get',
    params: data
  })
}

// 新增地址
export function addAddress(data) {
  return request({
    url: '/user/user-address/add',
    method: 'post',
    data: data
  })
}

// 修改地址
export function updateAddress(data) {
  return request({
    url: '/user/user-address/edit',
    method: 'post',
    data: data
  })
}

// 删除地址
export function delAddress(data) {
  return request({
    url: '/user/user-address/delete',
    method: 'post',
    data: data
  })
}
